<template>
    <!-- 
        Модальне вікно з імпортування холодних дзвінків
    -->

    <!-- Компонент модального вікна -->
    <modal @close="$emit('close')">
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('ColdCalls') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <div class="row">
                <!-- 
                    Зміщення щодо початку
                -->
                <div class="col-6">
                    <label for="offset" class="form-label">{{ this.$t('OffsetFromStart') }}</label>
                    <input type="text" id="offset" class="form-control" v-model="this.objParams.offset"/>
                </div>

                <!-- 
                    Кількість записів
                -->
                <div class="col-6">
                    <label for="limit" class="form-label">{{ this.$t('recordsQuantity') }}</label>
                    <input type="text" id="limit" class="form-control" v-model="this.objParams.limit"/>
                </div>
            </div>
            <div class="row">
                <!-- 
                    Cортування за видами діяльності
                    - фільтр на кведи
                -->
                <div class="col-6">
                    <label for="activities" class="form-label">{{ this.$t('SortingByTypeOfActivity') }}</label>
                    <Multiselect 
                        mode="tags"
                        id="activities"
                        v-model="this.activitiesArr"
                        :searchable="true"
                        :create-option="true"
                        :options="this.groupActiv"
                    />
                    <!-- <Multiselect 
                        mode="tags"
                        id="activities"
                        v-model="this.activitiesArr"
                        :searchable="true"
                        :create-option="true"
                        :options="this.activities"
                    /> -->
                </div>

                <!-- Папка/група заявок, куди фільтрувати отримані заявки -->
                <div class="col-6">
                    <label for="activities" class="form-label">{{ this.$t('SelectFolderGroupReq') }}</label>
                    <b-form-select v-model="selectFolderId" :options="folders"></b-form-select>
                </div>

                <!-- 
                    Виключити діяльність 
                    - масив кведів, які не входять в імпорт
                -->
                <div class="col-12">
                    <label for="exclude_activities" class="form-label">{{ this.$t("exclude_activities") }}</label>
                    <Multiselect 
                        mode="tags"
                        id="exclude_activities"
                        v-model="this.exclude_activitiesArr"
                        :searchable="true"
                        :create-option="true"
                        :options="this.activities"
                    />
                </div>

                <!-- 
                    Додаткові види діяльності
                -->
                <div class="col-12">
                    <label for="exclude_activities" class="form-label">{{ this.$t("additional_activities") }}</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" v-model="this.objParams.additional_activities" placeholder="69.21,96">
                </div>
            </div>
            <div class="row">

                <!-- Пошук за датою з -->
                <div class="col-6">
                    <label for="reg_date_from" class="form-label">{{ this.$t("searchFromDate") }}</label>
                    <input type="date" id="reg_date_from" class="form-control" v-model="this.objParams.reg_date_from">
                </div>

                <!-- Пошук за датою по -->
                <div class="col-6">
                    <label for="reg_date_to" class="form-label">{{ this.$t("searchToDate") }}</label>
                    <input type="date" id="reg_date_to" class="form-control" v-model="this.objParams.reg_date_to">
                </div>
            </div>
            <div class="row">

                <!-- Пошук за кодом міста -->
                <div class="col-6">
                    <label for="location" class="form-label">{{ this.$t("searchForRegionCode") }}</label>
                    <Multiselect 
                        mode="tags"
                        id="location"
                        v-model="this.locationArr"
                        :searchable="true"
                        :create-option="false"
                        :options="this.regions"
                    />
                </div>

                <!-- Виключити розташування -->
                <div class="col-6">
                    <label for="exclude_location" class="form-label">{{ this.$t("exclude_location") }}</label>
                    <Multiselect 
                        mode="tags"
                        id="exclude_location"
                        v-model="this.exclude_locationArr"
                        :searchable="true"
                        :create-option="false"
                        :options="this.regions"
                    />
                </div>
            </div>
            <div class="row">
                <!-- Юридична (company) або фізична (fop) особина -->
                <div class="col-6">
                    <label for="type" class="form-label">{{ this.$t('fopOrComp') }}</label>
                    <select v-model="this.objParams.type" id="type" class="form-select">
                        <option :value="''">{{ this.$t('Null') }}</option>
                        <option :value="'company'">{{ this.$t('comp') }}</option>
                        <option :value="'fop'">{{ this.$t('fop') }}</option>
                    </select>
                </div>

                <!-- Спосіб сортування (за зростанням 'ASC' або зниженням 'DESC') -->
                <div class="col-6">
                    <label for="sort" class="form-label">{{ this.$t("SortingMethod") }}</label>
                    <select id="sort" class="form-select" v-model="this.objParams.sort">
                        <option value="ASC">{{ this.$t('За зростанням') }}</option>
                        <option value="DESC">{{ this.$t('За спаданням') }}</option>
                    </select>
                </div>

            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="row">
                <div class="col-12">

                    <!-- Кнопка "Закрити", закриває вікно -->
                    <button 
                        class="btn btn-outline-success" 
                        style="margin-right: 20px;" 
                        @click="this.$emit('close')"
                    >
                        {{ this.$t('close') }}
                    </button>
                    
                    <!-- Кнопка "Отримати", отримуємо дані по холодним дзвінкам -->
                    <button 
                        class="btn btn-success" 
                        type="button" 
                        @click="sort"
                    >
                        {{ this.$t('get') }}
                    </button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { ColdCalls, coldCallFolders } from '@/API.js'; // клас для взаємодії з API 
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new ColdCalls();
let apicoldCallFolders = new coldCallFolders();

export default ({
    data(){
        return {
            objParams: {
                offset: '',
                type: 'fop',
                limit: '100',
                reg_date_from: '',
                reg_date_to: '',
                activities: '',
                location: '',
                exclude_activities: '',
                exclude_location: '',
                sort: '',
                additional_activities: ''
            },
            selectFolderId: '',
            folders: [],
            groupActiv: [
                {
                    label: this.$t('QuedasforShopping'),
                    value: '47.1,47.4,47.5,47.6,47.7'
                },
                {
                    label: this.$t('QuedasforHoreca'),
                    value: '47.2,56.1,56.2,56.3'
                },
            ],
            activities: [
                {
                    label: '47.1 (Роздрібна торгівля в неспеціалізованих магазинах)',
                    value: '47.1'
                },
                {
                    label: '47.2 (Роздрібна торгівля продуктами харчування, напоями та тютюновими виробами в спеціалізованих магазинах)',
                    value: '47.2'
                },
                {
                    label: '47.4 (Роздрібна торгівля інформаційним і комунікаційним устаткованням у спеціалізованих магазинах)',
                    value: '47.4'
                },
                {
                    label: '47.5 (Роздрібна торгівля іншими товарами господарського призначення в спеціалізованих магазинах)',
                    value: '47.5'
                },
                {
                    label: '47.6 (Роздрібна торгівля товарами культурного призначення та товарами для відпочинку в спеціалізованих магазинах)',
                    value: '47.6'
                },
                {
                    label: '47.7 (Роздрібна торгівля іншими товарами в спеціалізованих магазинах)',
                    value: '47.7'
                },
                {
                    label: '56.1 (Діяльність ресторанів, надання послуг мобільного харчування)',
                    value: '56.1'
                },
                {
                    label: '56.2 (Постачання готових страв)',
                    value: '56.2'
                },
                {
                    label: '56.3 (Обслуговування напоями)',
                    value: '56.3'
                },
            ],
            regions: [
                {
                    "label": "02 - Вінницька",
                    "value": "02"
                },
                {
                    "label": "03 - Волинська",
                    "value": "03"
                },
                {
                    "label": "04 - Дніпропетровська",
                    "value": "04"
                },
                {
                    "label": "05 - Донецька",
                    "value": "05"
                },
                {
                    "label": "06 - Житомирська",
                    "value": "06"
                },
                {
                    "label": "07 - Закарпатська",
                    "value": "07"
                },
                {
                    "label": "08 - Запорізька",
                    "value": "08"
                },
                {
                    "label": "09 - Івано-Франківська",
                    "value": "09"
                },
                {
                    "label": "10 - Київська",
                    "value": "10"
                },
                {
                    "label": "11 - Кіровоградська",
                    "value": "11"
                },
                {
                    "label": "12 - Луганська",
                    "value": "12"
                },
                {
                    "label": "13 - Львівська",
                    "value": "13"
                },
                {
                    "label": "14 - Миколаївська",
                    "value": "14"
                },
                {
                    "label": "15 - Одеська",
                    "value": "15"
                },
                {
                    "label": "16 - Полтавська",
                    "value": "16"
                },
                {
                    "label": "17 - Рівненська",
                    "value": "17"
                },
                {
                    "label": "18 - Сумська",
                    "value": "18"
                },
                {
                    "label": "19 - Тернопільська",
                    "value": "19"
                },
                {
                    "label": "20 - Харківська",
                    "value": "20"
                },
                {
                    "label": "21 - Херсонська",
                    "value": "21"
                },
                {
                    "label": "22 - Хмельницька",
                    "value": "22"
                },
                {
                    "label": "23 - Черкаська",
                    "value": "23"
                },
                {
                    "label": "24 - Чернівецька",
                    "value": "24"
                },
                {
                    "label": "25 - Чернігівська",
                    "value": "25"
                },
                {
                    "label": "26 - м. Київ",
                    "value": "26"
                }
            ],
            // multiselect видає помилки при обробці запиту, треба створити резервні змінні
            activitiesArr: [],
            exclude_activitiesArr: [],
            locationArr: [],
            exclude_locationArr: [],
            // multiselect видає помилки при обробці запиту, треба створити резервні змінні
        }
    },
    props: ['objFolders', 'folderId'],
    components: { 
        modal, 
        Multiselect, 
    },
    created(){
        this.getFolders();
    },
    methods: {
        sort(){
            this.objParams.activities = this.activitiesArr.join(',')
            this.objParams.exclude_activities = this.exclude_activitiesArr.join(',')
            this.objParams.location = this.locationArr.join(',')
            this.objParams.exclude_location = this.exclude_locationArr.join(',')

            if (this.objParams.activities == '') {
                //перевіряємо чи вказані кведи
                this.$toast.error(this.$t('warningSelectKvedSort'));
                return
            }

            if (this.selectFolderId == '') {
                //перевіряємо чи обрана папка
                this.$toast.error(this.$t('warningSelectFolder'));
                return
            }

            apiServe.importCalls(this.selectFolderId, this.objParams).then(result =>{
                if(result.status == 'done'){
                    this.$toast.success(this.$t('done'))
                    this.$emit('getdata')
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "selectFolderId": this.selectFolderId,
                        "params": this.objParams
                    }

                    var errorText = 'Помилка імпорту холодних дзвінків'; 
                    var statusRequest = result.status;
                    var api = 'importCalls';
                    var fileRequest = 'src/views/calls/cold/import.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        getFolders(){
            this.folders = [];

            if(this.objFolders){
                for(var item in this.objFolders){
                    this.folders.push({
                        text: this.objFolders[item].name,
                        value: this.objFolders[item].callFolderId,
                    })
                }
            } else {
                /*
                    Функція для отримання папок холодних дзвінків
                    Викликає API для отримання папок з параметрами з об'єкта objParams.
                */

                var objParamsFolders = {
                    page: '1',
                    pagelimit: '100'
                }

                // Обираємо одразу папку в якій знаходимось
                this.selectFolderId = this.folderId

                apicoldCallFolders.getFolders(objParamsFolders).then(result => {
                    if(result.status == 'done'){
                        for(var item in result.data.items){
                            var rows = result.data.items;
                            this.folders.push({
                                text: rows[item].name,
                                value: rows[item].callFolderId,
                            })
                        }
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка отримання папок для імпорту'; 
                        var statusRequest = result.status;
                        var api = 'getFolders';
                        var fileRequest = 'src/views/calls/cold/import.vue';
                        var params = objParamsFolders;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        }
    },
    mounted(){

    },
    computed: {
        user() {
            return storeS.userbase
        },
        checks(){
            return storeS.checks
        },
    }
})
</script>
<style scoped>
    .col-6, .col-12 {
        margin: 10px 0
    }
</style>
<template>
    <!-- сторінка холодні дзвінки -->
    <PageHeader :title="title" :items="items" />

    <!-- Кнопка назад, повертає в холодні дзвінки з вибором папки -->
    <div class="row g-4 mb-3">
        <div class="col-sm-auto">
            <div>
                <router-link 
                    to="/calls/cold" 
                >
                    <b-button variant="outline-dark" class="waves-effect waves-light">
                        <i class="ri-arrow-left-line align-bottom"></i> 
                        {{$t('back')}}
                    </b-button>
                </router-link>
            </div>
        </div>
    </div>


    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
        <button id="info" @click="turnOnInfo" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>
    <!-- інфо-блоки -->
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <!-- компонент таблиці -->
    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms[2000]"
        @create="showImportForm = true" 
        @changeFilter="changeFilter" 
        @clearFilter="clearFilter" 
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
        :tabs="othertabs"
        @changeTab="changeTab"
        @sortColumn="sortColumn" 
    />

    <!-- карточка заявки -->
    <viewBox 
        v-for="view in viewForms"
        @close="this.viewForms = 0" 
        :shortapplic="cardApplic"
        @getdata="getdata"
        @edit = 'edit'
        @remove="remove"
        @toWork="toWork" 
        @toCancel="toCancel" 
        @toClose ="toClose"
        @toSnooze = "toSnooze"
        @toThrow = "toThrow"
        @openUnregistered = "openUnregistered"
        @openRegistered = "openRegistered"
        >
    </viewBox>

    <!-- форма для зміни холодного дзвінка -->
    <createForm v-if="modalShow === true" @close="this.modalShow = false" @getData="this.getdata()" :perm="this.perm" :obj="this.cardApplic"></createForm>

    <!-- Форма для імпорту -->
    <importForm v-if="showImportForm === true" @close="this.showImportForm = false" @getdata="this.getdata()" :folderId="this.$route.params.id"></importForm>

</template>

<script>
import importForm from './import.vue'
import createForm from './create.vue'
import PageHeader from "@/components/page-header";
import tablecustom from '@/components/globaltable/index';
import infoblocks from '@/components/info/blocks'
import viewBox from './view/viewapplic'
import { ColdCalls, coldCallFolders } from '@/API'
import { mutatePriority, mutateStatus } from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new ColdCalls();
let apicoldCallFolders = new coldCallFolders();

export default {
    components: {
        PageHeader,
        tablecustom,
        infoblocks,
        viewBox,
        createForm,
        importForm
    },
    data(){
        return {
            // title: "ColdCalls",
            title: this.$route.params.title,
            timeout: '',
            form: '',
            viewForm: 0,
            viewForms: 0,
            closeView: false,
            cardApplic: {},
            ifNextopen: "",
            ifPrevopen: "",
            objPages: {},
            dataParams: {
                status: true,
                page: "coldCalls"
            },
            objParams:{
                page: '1',
                pagelimit: '25',
                search: '',
                priority: '',
                status: '',
                pr1: '',
                pr2: '',
                of: 'create_datetime',
                ot: 'desc'
            },
            perm: false,
            modalShow: false,
            columns: [
                {
                    name: "ID",
                    text: "requestId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Status"),
                    text: 'status',
                    align: "left",
                    status: true,
                    mutate: (item, obj) => item == 'callNow' ? this.$t(this.mutateStatus(item, obj).name) : this.mutateStatus(item, obj).name,
                    mutateClass: (item, obj) => this.mutateStatus(item, obj).color
                },
                {
                    name: this.$t("phone_num"),
                    text: "userPhone",
                    align: "right",
                    status: true,
                },
                {
                    name: this.$t("FOp"),
                    text: "userName",
                    align: "right",
                    status: true,
                },
                {
                    name: this.$t("date_create"),
                    text: "createDatetime",
                    align: "right",
                    status: true,
                    sort: 'create_datetime',
                    sortType: 'desc'
                },
            ],
            rows: [], 
            infoblockShow: false,  
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info"
                },
                {
                    title: this.$t('new'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('statusInWork'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning"
                },
                {
                    title: this.$t('balance'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger"
                },
            ],
            showImportForm: false,
            othertabs: [
                {
                    title: this.$t('ForYesterday'),
                    tab: "requestsYesterday",
                    color: "#3498DB",
                    obj: "requestsYesterday",
                    value: "requestsYesterday",
                    checked: true
                },
                {
                    title: this.$t('ForWeek'),
                    tab: "requestsWeek",
                    color: "#1ABC9C",
                    obj: "requestsWeek",
                    value: "requestsWeek",
                    checked: true
                },
                {
                    title: this.$t('ForMonth'),
                    tab: "requestsMonth",
                    color: "#c21818",
                    obj: "requestsMonth",
                    value: "requestsMonth",
                    checked: true
                },
                {
                    title: this.$t('PendingDeletion'),
                    tab: "wait_cancel",
                    color: "#212222",
                    obj: "wait_cancel",
                    value: "wait_cancel",
                    checked: true
                },
            ],
        }
    },
    created(){
        this.getdata();
    },
    mounted(){
        this.eventB.on('updateColdCalls', (status) => {
            if(status === true){
                this.getdata()
            }
        })
    },
    methods: {
        changelimit(e){
            // Зміна ліміту
            this.objParams.pagelimit = String(e)
            this.getdata()
        },
        turnOnInfo(){
            // включити інфоблоки
            if(this.infoblockShow == false){
                this.getLimits()
            }
            this.infoblockShow = !this.infoblockShow
        },
        getLimits(){
            // отримати ліміти по залишку холодних
            apiServe.statistic().then(result => {
                if(result.status == 'done'){
                    this.information[3].value = result.data["REGISTRATIONS"].balance + '/' + result.data["REGISTRATIONS"].limit
                }
            })
        },
        edit() {
            // зміна заявки
            this.perm = true
            this.modalShow = true
        },
        searchB(e) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.objParams.search = e
                this.getdata()
            }, 777)
        },
        getdata(e){
            this.objParams.page = e == undefined ? '' : e 
            
            // Відкриваємо папку з заявками по папці
            apicoldCallFolders.openFolder(this.$route.params.id, this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.objPages = result.data;

                    this.information[0].value = result.data.total
                    this.information[1].value = result.data.countNew
                    this.information[2].value = result.data.countInJob

                    // впав сокет на зміну статусу на "Подзвонити зараз", костиль, юзати тільки на локалці

                    // let obj = {
                    //   requestIds: []
                    // }

                    // this.rows.forEach(el => {
                    //   if(el.status == "delayed" && new Date(el.delayedDatetime) < new Date()) {
                    //     obj.requestIds.push(el.requestId)
                    //   }
                    // })
                    
                    // if(obj.requestIds.length > 0) {
                    //   apiServe.packCallNow(obj).then(result=> {
                    //     if(result.status == 'done') {
                    //       this.$toast.success(this.$t('success'))
                    //     }
                    //   })
                    // }


                    // впав сокет на зміну статусу на "Подзвонити зараз", костиль, юзати тільки на локалці
                } else {
                    this.$toast.error(this.$t('error') + ` #10012`);

                    var errorText = 'Помилка відкриття папки'; 
                    var statusRequest = result.status;
                    var api = 'openFolder';
                    var fileRequest = 'src/views/calls/cold/all.vue';
                    var params = {param: this.$route.params.id, form: this.objParams};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        importCalls() {

        },
        open(e){
            var id = e.requestId ? e.requestId : e;
            this.ifPrevopen = id == this.rows[0].requestId ? false : true;
            this.ifNextopen = id == this.rows[this.rows.length-1].requestId ? false : true;

            apiServe.getCall(id).then(result => {
                if(result.status == 'done'){
                    this.cardApplic = result.data;
                    this.viewForms++
                } else {
                    this.$toast.error(this.$t("error"))

                    var errorText = 'Помилка відкриття холодного дзвінка'; 
                    var statusRequest = result.status;
                    var api = 'getCall';
                    var fileRequest = 'src/views/calls/cold/all.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        changeFilter(name, value) {
            // Зміна фільтру
            if(name == 'pr') {
                this.objParams.pr1 = value[0]
                this.objParams.pr2 = value[1]
            }
            this.objParams[name] = value
            this.getdata()
        },
        sortColumn(e, o){
            // сортування
            this.objParams.of = e.sort
            this.objParams.ot = o == 'desc' ? 'asc' : 'desc'
            this.getdata()
        },
        changeTab(name, value){
            /*
                Функція для зміни активного табу та виклику функції отримання даних getdata з новими параметрами фільтрації.
            */

            if(value == 'requestsYesterday'){
                // Отримання вчорашньої дати та сьогоднішньої дати
                const pr1 = this.getYesterday();
                const pr2 = this.formatDate(new Date());
                this.objParams.pr1 = pr1;
                this.objParams.pr2 = pr2;
                this.getdata()
            } else if (value == 'requestsWeek'){
                // Отримання періоду за тиждень
                const weekPeriod = this.getWeekPeriod();
                this.objParams.pr1 = weekPeriod.pr1;
                this.objParams.pr2 = weekPeriod.pr2;
                this.getdata()
            } else if (value == 'requestsMonth'){
                // Отримання періоду за місяць
                const monthPeriod = this.getMonthPeriod();
                this.objParams.pr1 = monthPeriod.pr1;
                this.objParams.pr2 = monthPeriod.pr2;
                this.getdata()
            } else if (value == 'wait_cancel'){
                this.objParams.status = 'wait_cancel'
                this.getdata()
            }
        },
        getYesterday() {
            /*
                Функція для отримання вчорашньої дати у форматі "yyyy-MM-dd".
                
                Повертає:
                - formattedYesterday: Вчорашня дата у вказаному форматі.
            */

            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            const formattedYesterday = this.formatDate(yesterday);

            return formattedYesterday;
        },
        getWeekPeriod() {
            /*
                Функція для отримання періоду за тиждень у форматі "yyyy-MM-dd".
                
                Повертає:
                - Об'єкт з початковою (pr1) та кінцевою (pr2) датами періоду за тиждень.
            */

            const today = new Date();
            const endDate = new Date(today);
            const startDate = new Date(today);

            // Встановлюємо кінцеву дату (сьогодні)
            endDate.setHours(0, 0, 0, 0);

            // Встановлюємо початкову дату (сьогодні - 7 днів)
            startDate.setDate(startDate.getDate() - 7);
            startDate.setHours(0, 0, 0, 0);

            return {
                pr1: this.formatDate(startDate),
                pr2: this.formatDate(endDate),
            };
        },
        getMonthPeriod() {
            /*
                Функція для отримання періоду за місяць у форматі "yyyy-MM-dd".
                
                Повертає:
                - Об'єкт з початковою (pr1) та кінцевою (pr2) датами періоду за місяць.
            */

            const endDate = new Date(); // Починаємо з поточної дати
            const startDate = new Date(endDate); // Копіюємо поточну дату для початкової точки

            // Встановлюємо кінцеву дату (сьогодні)
            endDate.setHours(0, 0, 0, 0);

            // Встановлюємо початкову дату (сьогодні - 1 місяць)
            startDate.setMonth(startDate.getMonth() - 1);
            startDate.setHours(0, 0, 0, 0);

            // Форматуємо та повертаємо результат
            return {
                pr1: this.formatDate(startDate),
                pr2: this.formatDate(endDate),
            };
        },
        formatDate(date) {
            /*
                Функція для форматування дати у форматі "yyyy-MM-dd".
                
                Параметри:
                - date: Об'єкт дати, яку потрібно сформатувати.
                
                Повертає:
                - Рядок, представляючий дату у вказаному форматі.
            */

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        mutateStatus(e, obj){
            return mutateStatus(e, obj);
        },
        mutatePriority(e){
            return mutatePriority(e);
        },
    },
    computed: {
        user() {
            return storeS.userbase
        },
        perms() {
            return storeS.perms
        },
        checks() {
            return storeS.checks
        },
        workers() {
            return storeS.workers
        },
        tours() {
            return storeS.tours
        },
    }
}

</script>